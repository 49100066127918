import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import CallIcon from '@material-ui/icons/Call';

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function ManualDialBtn(props) {
  const classes = useStyles();

  const { disabled, dial } = props;

  return (
    <Fab variant="extended" color="primary" disabled={disabled} onClick={dial}>
      <CallIcon className={classes.extendedIcon} />
      Dial
    </Fab>
  );
}
