const _ = require('lodash');

export async function SubmitContactTier1Broker(data) {
  try {
    const headers = new Headers({
      'x-api-key': 'sxRIb5Mr2A95Pa2xDIrI5hWxRt7Jq9nahZU4pUf9',
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
    };
    const response = await fetch(
      'https://x5ny78c7z5.execute-api.eu-central-1.amazonaws.com/prod/contacttier1broker',
      options
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error(error);
  }
}

export async function PolicySearch(searchType, searchValue, searchColumn) {
  if (searchValue === '') {
    return [];
  }
  try {
    const headers = new Headers({
      'x-api-key': 'sxRIb5Mr2A95Pa2xDIrI5hWxRt7Jq9nahZU4pUf9',
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        searchType: searchType,
        searchValue: searchValue,
        searchColumn: searchColumn,
      }),
    };
    const response = await fetch(
      'https://x5ny78c7z5.execute-api.eu-central-1.amazonaws.com/prod/policyquery',
      options
    );
    const result = await response.json();
    return result.Items;
  } catch (error) {
    throw new Error(error);
  }
}

export async function ClaimSearch(searchType, searchValue, searchColumn) {
  if (searchValue === '') {
    return [];
  }
  try {
    const headers = new Headers({
      'x-api-key': 'sxRIb5Mr2A95Pa2xDIrI5hWxRt7Jq9nahZU4pUf9',
      'Content-Type': 'application/json',
    });
    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        searchType: searchType,
        searchValue: searchValue,
        searchColumn: searchColumn,
      }),
    };
    const response = await fetch(
      'https://x5ny78c7z5.execute-api.eu-central-1.amazonaws.com/prod/claimquery',
      options
    );
    const result = await response.json();
    return result.Items;
  } catch (error) {
    throw new Error(error);
  }
}

export async function GetQueueStats(queues) {
  try {
    const headers = new Headers({
      'x-api-key': 'sxRIb5Mr2A95Pa2xDIrI5hWxRt7Jq9nahZU4pUf9',
      'Content-Type': 'application/json',
    });

    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({queues: _.map(queues, 'queueARN')}),
    };
    const response = await fetch(
      'https://x5ny78c7z5.execute-api.eu-central-1.amazonaws.com/prod/connectmetrics',
      options
    );
    const result = await response.json();

    const metricArray = [];
    result.MetricResults &&
      result.MetricResults.forEach((dim) => {
        const metricSet = {};
        const qItem = _.find(queues, {
          queueARN: dim.Dimensions.Queue.Arn,
        });
        metricSet.queueName = qItem.name;
        metricSet.queueStats = {};
        dim.Collections.forEach((colItem) => {
          const key = colItem.Metric.Name;
          const value = colItem.Value;
          metricSet.queueStats[key] = value;
        });
        metricArray.push(metricSet);
      });
    return metricArray;
  } catch (error) {
    throw new Error(error);
  }
}

export function GetBrokerList() {
  const brokers = [
    {
      // fsg complaint - 0161 768 1186
      value:
        'arn:aws:connect:eu-central-1:191184140041:instance/55264598-9448-402e-94ae-14826475f16e/queue/952b5413-0293-42c9-97b6-d429c6e1e70d',
      label: 'Freedom Brokers',
    },
    {
      // Pukka services - 02039621879
      value:
        'arn:aws:connect:eu-central-1:191184140041:instance/55264598-9448-402e-94ae-14826475f16e/queue/c8a49428-d8bc-4724-ac67-8969ee2d2388',
      label: 'Pukka Services',
    },
    {
      // fsg testing - 03455280197
      value:
        'arn:aws:connect:eu-central-1:191184140041:instance/55264598-9448-402e-94ae-14826475f16e/queue/118d8429-0e77-48e1-8a6a-4496829655f3',
      label: 'Action 365',
    },
    {
      // stella
      value:
        'arn:aws:connect:eu-central-1:191184140041:instance/55264598-9448-402e-94ae-14826475f16e/queue/adb4cf1d-0c42-41e2-b56d-9c5b5a9b92dd',
      label: 'Stella',
    },
  ];

  return brokers;
}

export function GetBrokerListFP() {
  const brokers = [
    {
      // Pukka
      value:
        'arn:aws:connect:eu-central-1:191184140041:instance/55264598-9448-402e-94ae-14826475f16e/queue/0bdf0bc5-eb92-4d49-bf6c-3496b86a01ff',
      label: 'Pukka',
    },
    {
      // NIG
      value:
        'arn:aws:connect:eu-central-1:191184140041:instance/55264598-9448-402e-94ae-14826475f16e/queue/100c450a-aef6-478c-8841-ce4ca8f89499',
      label: 'NIG',
    },
    {
      // Stella
      value:
        'arn:aws:connect:eu-central-1:191184140041:instance/55264598-9448-402e-94ae-14826475f16e/queue/661fe084-904b-474b-b97d-2ad225359d4d',
      label: 'Stella',
    },
    {
      // Zurich
      value:
        'arn:aws:connect:eu-central-1:191184140041:instance/55264598-9448-402e-94ae-14826475f16e/queue/ab56e20a-5630-44cc-967e-8c535e9b9c9f',
      label: 'Zurich',
    },
  ];

  return brokers;
}

export function GetBrokerListT1() {
  const brokers = [
    {
      value: 'freedom',
      label: 'Freedom claims',
    },
    {
      value: 'nissan',
      label: 'Nissan Insurance',
    },
    {
      value: 'nova',
      label: 'Nova Insurance',
    },
    {
      value: 'clements',
      label: 'Clements Worldwide',
    },
    {
      value: 'tansar',
      label: 'Tansar Non-Fault',
    },
    {
      value: 'c & p',
      label: 'C & P Insurance',
    },
    {
      value: 'vanorama',
      label: 'Vanorama',
    },
    {
      value: 'aldium',
      label: 'Aldium Claims',
    },
    {
      value: 'routen',
      label: 'Routen Chaplin Claims',
    },
    {
      value: 'cabsurance',
      label: 'Cabsurance',
    },
    {
      value: 'invicta',
      label: 'Invicta Insurance',
    },
    {
      value: 'oddie',
      label: 'Oddie Dalton',
    },
    {
      value: 'simply',
      label: 'Simply Insurance',
    },
    {
      value: 'quantum',
      label: 'Quantum',
    },
    {
      value: 'business compare',
      label: 'Business Compare',
    },
    {
      value: 'newlink',
      label: 'Newlink Insurance',
    },
    {
      value: 'intervention',
      label: 'Tier 1 Intervention',
    },
    {
      value: 'interception',
      label: 'Tier 1 Interception',
    },
    {
      value: 'drayton',
      label: 'Drayton Insurance',
    },
    {
      value: 'pukkanonfault',
      label: 'Pukka Non-Fault',
    },
  ];

  return brokers;
}

export function MatchBroker(data) {
  let broker = '';
  const brokers = GetBrokerListT1();
  if (data.length > 0) {
    broker = data[0].Insurer.toLowerCase();

    const foundItem = _.find(brokers, (b) => {
      const lcBroker = b.value.toLowerCase();
      return broker.includes(lcBroker);
    });
    return foundItem.value;
  } else {
    return false;
  }
}
