import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  flex: {
    flex: 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  logo: {
    maxWidth: 40,
    marginRight: 20,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function AppLayout(props) {
  const { agentProfile } = props;
  const classes = useStyles();
  return (
    <React.Fragment>
      <AppBar position="sticky" className={classes.appBar} color="secondary">
        <Toolbar>
          <img src="/logo_white.png" alt="logo" className={classes.logo} />
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            className={classes.flex}
          >
            Freedom Innovate CCP
          </Typography>
          {agentProfile && (
            <Chip
              color="primary"
              icon={<GroupWorkIcon />}
              label={agentProfile}
            />
          )}
        </Toolbar>
      </AppBar>
      <main className={classes.content}>{props.children}</main>
    </React.Fragment>
  );
}

export default AppLayout;
