import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

export default function AdditionalDrivers(props) {
  const { drivers } = props;
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="additional drivers table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>DOB</TableCell>
            <TableCell>Sex</TableCell>
            <TableCell>Relation to Insured</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {drivers.map((driver, idx) => (
            <TableRow key={idx}>
              <TableCell>{driver.fullname}</TableCell>
              <TableCell>{moment(driver.DOB).format('ll')}</TableCell>
              <TableCell>{driver.sex}</TableCell>
              <TableCell>{driver.relationToInsured}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
