import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

export default function ClaimDetails(props) {
  const { data } = props;
  return (
    <ExpansionPanel TransitionProps={{ unmountOnExit: true }}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="body2">{`Status: ${data.claimStatus} - Liability: ${data.liability}`}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Name:
            </Typography>
            <Typography variant="body2">{data.fullName}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              DOB:
            </Typography>
            <Typography variant="body2">
              {moment(data.phDOB).format('ll')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Reported On:{' '}
            </Typography>
            <Typography variant="body2">
              {moment(`${data.reportedDate} ${data.reportedTime}`).isValid()
                ? moment(`${data.reportedDate} ${data.reportedTime}`).format(
                    'lll'
                  )
                : moment(data.reportedDate).isValid()
                ? moment(data.reportedDate).format('ll')
                : ''}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Reported By:
            </Typography>
            <Typography variant="body2">{data.reportedBy}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Vehicle:{' '}
            </Typography>
            <Typography variant="body2">{`${data.vehicleMake} ${data.vehicleModel} ${data.vehicleReg}`}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Circumstances:
            </Typography>
            <Typography variant="body2">
              {data.accidentCircumstances}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Details:
            </Typography>
            <Typography variant="body2">{data.accidentDetails}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Location:
            </Typography>
            <Typography variant="body2">{data.accidentLocation}</Typography>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
