import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import App from './App';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      light: '#9b76cd',
      main: '#6b499c',
      dark: '#3c1f6d',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff80a8',
      main: '#ef4b79',
      dark: '#b7004d',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
    // fontSize: 12,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': 'Montserrat',
      },
    },
  },
});

const ThemedApp = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
};

ReactDOM.render(<ThemedApp />, document.getElementById('root'));
