/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from 'react';
import 'amazon-connect-streams';
import 'amazon-connect-chatjs';
import '../../index.css';

export default function CCP(props) {
  const containerDiv = useRef(null);

  const agentConnectEvent = (agent) => {
    props.setAgent(agent);
  };
  const contactConnectEvent = (contact) => {
    props.setContact(contact);
  };

  useEffect(() => {
    connect.core.initCCP(containerDiv.current, {
      ccpUrl: 'https://fsgprod.awsapps.com/connect/ccp-v2/',
      loginPopupAutoClose: true,
      region: 'eu-central-1',
      softphone: {
        allowFramedSoftphone: true,
      },
    });

    connect.agent(agentConnectEvent);
    connect.contact(contactConnectEvent);
  }, []);

  return <div className='ccp-container' ref={containerDiv} />;
}
