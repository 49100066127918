import React, { useEffect, useState } from 'react';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useQuery, gql } from '@apollo/client';
import DispositionContact from './disposition-contact';

const GET_OPTS = gql`
  query {
    whoCalledOptions {
      whoCalled
      reasonForCalling
      descOfCall
    }
    capacityProvidersOptions
  }
`;

export default function Disposition({
  currentContact,
  agentName,
  newAgentState,
  oldAgentState,
}) {
  const whoCalledRes = useQuery(GET_OPTS);
  const [capacityProviderOpts, setCapacityProviderOpts] = useState([]);
  const [allOpts, setAllOpts] = useState([]);
  const [display, setDisplay] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [currentContactId, setCurrentContactId] = useState(null);
  const [sendDispositions, setSendDispositions] = useState(false);

  useEffect(() => {
    if (currentContact && currentContact.contactId !== currentContactId) {
      console.log('currentContact: ', currentContact);
      const newContact = {
        contactId: currentContact.contactId,
        inbound: currentContact.isInbound(),
        dispositionSent: false,
        whoCalled: '',
        reasonForCalling: '',
        descOfCall: '',
        capacityProvider: '',
        claimRef: '',
      };
      setContacts([...contacts, newContact]);
      setCurrentContactId(currentContact.contactId);
    }
  }, [currentContact, currentContactId, contacts]);

  useEffect(() => {
    const { data, error } = whoCalledRes;
    if (data && data.whoCalledOptions) {
      setAllOpts(data.whoCalledOptions);
      setCapacityProviderOpts(data.capacityProvidersOptions);
    }
    if (error) {
      console.log('error :', error);
    }
  }, [whoCalledRes]);

  const updateContact = (updated) => {
    const { contactId, dispositionSent } = updated;
    console.log('updated: ', updated);
    const index = contacts.findIndex((x) => x.contactId === contactId);
    const newContacts = [...contacts];
    newContacts[index]['dispositionSent'] = dispositionSent;
    console.log('newContacts: ', newContacts);
    setContacts(newContacts);
  };

  return (
    <Card style={{ marginBottom: '0.6em' }}>
      <CardHeader
        title='Dispositions'
        avatar={
          <QuestionAnswerIcon
            style={{ justifyContent: 'center', display: 'flex' }}
          />
        }
        style={{
          paddingBottom: '0.6em',
          paddingTop: '0.6em',
          color: display ? 'white' : 'grey',
        }}
      />
      <CardContent
        style={{
          paddingBottom: '0.01em',
          paddingTop: '0.01em',
        }}
      >
        <Grid container spacing={3}>
          {contacts.map((contact, idx) => {
            const current = currentContactId === contact.contactId;
            const send = oldAgentState === 'AfterCallWork' && contact.inbound;
            return (
              <DispositionContact
                key={idx}
                contactId={contact.contactId}
                inbound={contact.inbound}
                currentContact={current}
                allOpts={allOpts}
                capacityProviderOpts={capacityProviderOpts}
                agentName={agentName}
                send={send}
                updateContact={(e) => updateContact(e)}
              />
            );
          })}
        </Grid>
        {/* <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">ContactId</TableCell>
              <TableCell align="left">Inbound</TableCell>
              <TableCell align="left">Current</TableCell>
              <TableCell align="left">Sent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts.map((contact) => {
              return (
                <TableRow>
                  <TableCell>{contact.contactId}</TableCell>
                  <TableCell>
                    {contact.inbound ? 'Inbound' : 'Outbound'}
                  </TableCell>
                  <TableCell>
                    {contact.contactId === currentContactId ? 'True' : 'False'}
                  </TableCell>
                  <TableCell>
                    {contact.dispositionSent ? 'True' : 'False'}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table> */}
      </CardContent>
    </Card>
  );
}
