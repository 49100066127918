import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
} from '@material-ui/core';
import { useQuery, gql, useMutation } from '@apollo/client';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const ADD_OPT = gql`
  mutation AddIVROption(
    $user: String!
    $whoCalled: String!
    $reasonForCalling: String!
    $descOfCall: String
  ) {
    addIVRDispositionOption(
      user: $user
      whoCalled: $whoCalled
      reasonForCalling: $reasonForCalling
      descOfCall: $descOfCall
    )
  }
`;

const DELETE_OPT = gql`
  mutation DeleteIVROption($id: String!) {
    deleteIVRDispositionOption(id: $id)
  }
`;

const GET_OPTS = gql`
  query {
    whoCalledOptions {
      id
      whoCalled
      reasonForCalling
      descOfCall
    }
    capacityProvidersOptions
  }
`;

export default function DispositionOptionsPage() {
  const getOptsRes = useQuery(GET_OPTS);
  const [addDispositionOpt, addDispositionOptRes] = useMutation(ADD_OPT);
  const [deleteDispositionOpt, deleteDispositionOptRes] = useMutation(DELETE_OPT);
  const [allOpts, setAllOpts] = useState([]);
  const [newWhoCalled, setNewWhoCalled] = useState('');
  const [newReasonForCalling, setNewReasonForCalling] = useState('');
  const [newDescOfCall, setNewDescOfCall] = useState('');

  useEffect(() => {
    const { data, error } = getOptsRes;
    if (data && data.whoCalledOptions) {
      setAllOpts(data.whoCalledOptions);
    }
    if (error) {
      console.log('error :', error);
    }
  }, [getOptsRes]);

  useEffect(() => {
    if (
      addDispositionOptRes.data &&
      addDispositionOptRes.data.addIVRDispositionOption
    ) {
      window.location.reload();
    }

    if (
      deleteDispositionOptRes.data &&
      deleteDispositionOptRes.data.deleteIVRDispositionOption
    ) {
      window.location.reload();
    }
  }, [addDispositionOptRes, deleteDispositionOptRes]);

  const add = () => {
    addDispositionOpt({
      variables: {
        user: '',
        whoCalled: newWhoCalled,
        reasonForCalling: newReasonForCalling,
        descOfCall: newDescOfCall,
      },
    });
  };

  const deleteOpt = (id) => {
    deleteDispositionOpt({
      variables: {
        id,
      },
    });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Who Called</TableCell>
              <TableCell align="center">Reason for Call</TableCell>
              <TableCell align="center">Description For Calling</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <TextField
                  id="outlined-basic"
                  label="Who Called"
                  variant="outlined"
                  size="small"
                  value={newWhoCalled}
                  fullWidth={true}
                  onChange={(e) => setNewWhoCalled(e.target.value)}
                />
              </TableCell>
              <TableCell align="right">
                <TextField
                  id="outlined-basic"
                  label="Reason for Call"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  value={newReasonForCalling}
                  onChange={(e) => setNewReasonForCalling(e.target.value)}
                />
              </TableCell>
              <TableCell align="right">
                <TextField
                  id="outlined-basic"
                  label="Description of Call"
                  variant="outlined"
                  size="small"
                  value={newDescOfCall}
                  fullWidth={true}
                  onChange={(e) => setNewDescOfCall(e.target.value)}
                />
              </TableCell>
              <TableCell align="right">
                <IconButton aria-label="add" onClick={() => add()}>
                  <AddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
            {allOpts.map((opt) => (
              <TableRow
                key={opt.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {opt.whoCalled}
                </TableCell>
                <TableCell align="right">{opt.reasonForCalling}</TableCell>
                <TableCell align="right">{opt.descOfCall}</TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="delete"
                    onClick={() => deleteOpt(opt.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
