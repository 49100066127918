import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

import ClaimDetails from './details';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: '7px 10px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginTop: 10,
  },
}));

export default function ClaimView(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      {props.claimData.map((c, idx) => {
        return (
          <React.Fragment key={idx}>
            <Paper elevation={0} className={classes.root}>
              <Typography variant="caption">{`${c.caseType} - ${c.Insurer} - ${c.BU}`}</Typography>
              <br />
              <Typography variant="caption">{`${c.claimRef} - Incident Date: ${
                moment(`${c.accidentDate} ${c.accidentTime}`).isValid()
                  ? moment(`${c.accidentDate} ${c.accidentTime}`).format('lll')
                  : moment(c.accidentDate).isValid()
                  ? moment(c.accidentDate).format('ll')
                  : ''
              }`}</Typography>
            </Paper>
            <ClaimDetails data={c} />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}
