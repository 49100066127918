import React, { useState, useEffect } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import * as Functions from './../functions';

import LoadingBackdrop from '../components/loading-backdrop';

const useStyles = makeStyles({
  root: {
    // backgroundColor: '#57c5c7',
    padding: '10px 20px',
  },
  statContainer: {
    marginTop: 4,
  },
  statTitle: {
    marginTop: 30,
  },
  red: {
    backgroundColor: 'red',
  },
});

export default function Stats({ agentRoutingProfile }) {
  const classes = useStyles();
  const [stats, setStats] = useState({
    AGENTS_AFTER_CONTACT_WORK: 0,
    AGENTS_AVAILABLE: 0,
    AGENTS_ERROR: 0,
    AGENTS_NON_PRODUCTIVE: 0,
    AGENTS_ONLINE: 0,
    AGENTS_ON_CALL: 0,
    AGENTS_STAFFED: 0,
    CONTACTS_IN_QUEUE: 0,
    CONTACTS_SCHEDULED: 0,
    OLDEST_CONTACT_AGE: 0,
  });

  useEffect(() => {
    if (agentRoutingProfile) {
      console.log('agentRoutingProfile :', agentRoutingProfile);
      const filteredQueues = _.filter(
        agentRoutingProfile.queues,
        (q) => q.name !== null
      );
      const intervalID = setInterval(async () => {
        try {
          const qStats = await Functions.GetQueueStats(filteredQueues);
          qStats.length > 0 && setStats(qStats);
        } catch (error) {
          console.log(error);
        }
      }, 5000);

      return function cleanup() {
        clearInterval(intervalID);
      };
    }
  }, [agentRoutingProfile]);

  return (
    <>
      <LoadingBackdrop open={!stats.length > 0} />
      {stats.length > 0 &&
        stats.map((s, i) => {
          return (
            <React.Fragment key={i}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align="center"
                className={classes.statTitle}
              >
                {s.queueName} - Queue Stats
              </Typography>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
                className={classes.statContainer}
              >
                <Grid item>
                  <Paper className={classes.root}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Grid item>
                        <Typography variant="caption">Calls Waiting</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h4">
                          {s.queueStats.CONTACTS_IN_QUEUE}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={classes.root}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Grid item>
                        <Typography variant="caption">Agents Free</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h4">
                          {s.queueStats.AGENTS_AVAILABLE}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={classes.root}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Grid item>
                        <Typography variant="caption">Agents Busy</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h4">
                          {s.queueStats.AGENTS_ON_CALL -
                            s.queueStats.AGENTS_AFTER_CONTACT_WORK}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper className={classes.root}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Grid item>
                        <Typography variant="caption">Agents ACW</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h4">
                          {s.queueStats.AGENTS_AFTER_CONTACT_WORK}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper
                    className={clsx(
                      classes.root,
                      moment
                        .duration(s.queueStats.OLDEST_CONTACT_AGE, 'seconds')
                        .asMinutes() > 10
                        ? classes.red
                        : ''
                    )}
                  >
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Grid item>
                        <Typography variant="caption">Longest Wait</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h4">
                          {moment
                            .utc(
                              moment
                                .duration(
                                  s.queueStats.OLDEST_CONTACT_AGE,
                                  'seconds'
                                )
                                .asMilliseconds()
                            )
                            .format('HH:mm:ss')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
    </>
  );
}
