import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import Divider from '@material-ui/core/Divider';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import * as Functions from '../functions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 10,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: theme.typography.fontSize,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function ClaimSearch(props) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = async searchType => {
    handleClose();
    try {
      let searchColumn = 'claimRef';

      switch (searchType) {
        case 'tel':
          searchColumn = 'tel';
          break;
        case 'claimreffull':
          searchColumn = 'claimRef';
          break;
        case 'claimreftrimmed':
          searchColumn = 'claimRefTrimmed';
          break;
        default:
          break;
      }

      const data = await Functions.ClaimSearch(
        searchType,
        searchValue,
        searchColumn
      );

      props.updateClaimData(data);
    } catch (error) {
      console.log(error);
      props.updateClaimData([]);
    }
  };

  const handleChange = e => {
    setSearchValue(e.target.value.toUpperCase());
  };

  const clearData = () => {
    setSearchValue('');
    props.clearData('claim');
  };

  return (
    <Paper className={classes.root}>
      <IconButton className={classes.iconButton} aria-label="search" disabled>
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder="Claim Search"
        inputProps={{ 'aria-label': 'claim search' }}
        value={searchValue}
        onChange={handleChange}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="clear all"
        disabled={!searchValue && !props.hasData}
        onClick={clearData}
      >
        <ClearAllIcon />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        className={classes.iconButton}
        aria-label="search menu"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem
          key="claimreffull"
          disabled={!searchValue}
          onClick={() => {
            handleSearch('claimreffull');
          }}
        >
          Full Claim Reference
        </MenuItem>
        <MenuItem
          key="claimreftrimmed"
          disabled={!searchValue}
          onClick={() => {
            handleSearch('claimreftrimmed');
          }}
        >
          Trimmed Claim Reference (first 6 digits)
        </MenuItem>
        <MenuItem
          key="tel"
          disabled={!searchValue}
          onClick={() => {
            handleSearch('tel');
          }}
        >
          Telephone Number
        </MenuItem>
      </Menu>
    </Paper>
  );
}
