import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

import PolicyTransaction from './transaction';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    padding: '7px 10px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginTop: 10,
  },
}));

export default function PolicyView(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      {props.policyData.map((p, idx) => {
        return (
          <React.Fragment key={idx}>
            <Paper elevation={0} className={classes.root}>
              <Typography variant="caption">{`${p.broker} - Pukka - ${p.capacityProvider}`}</Typography>
              <br />
              <Typography variant="caption">{`${
                p.policyNumber
              } - Started: ${moment(p.policyStartDate).format(
                'lll'
              )}`}</Typography>
            </Paper>
            {p.transactions.map((t, tIdx) => {
              return <PolicyTransaction data={t} key={tIdx} />;
            })}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}
