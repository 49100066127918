import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Layout from './layouts/app-layout';
import CCP from './components/ccp';
import ContactAttributes from './components/contact-attributes';
import Stats from './components/stats';
import PolicyView from './components/policyView';
import PolicySearch from './components/policySearch';
import ClaimView from './components/claimView';
import ClaimSearch from './components/claimSearch';
import ManualDial from './components/manual-dial';
import ManualDialT1 from './components/manual-dial-t1';
import ManualDialFP from './components/manual-dial-fp';
import * as Functions from './functions';
import Disposition from './components/disposition';
import DispositionOptionsPage from './components/disposition-options';
import {ApolloClient, InMemoryCache, ApolloProvider} from '@apollo/client';

const showDispositions = (profileName) => {
  console.log('profileName: ', profileName);
  const hideProfiles = ['FSG - Complaints', 'FSG - Complaints L2'];
  const showProfiles = [
    '1 - MGA - FP Priority Existing',
    '1 - MGA - FP Priority Callbacks',
    '1 - MGA - Mitigation',
    '1 - MGA - TPPD',
    '1 - MGA - CHE',
    'Testing',
    'DISPO TEST',
    'MGA - First Party',
    'MGA - Technical Hub',
    'Zurich - First Party',
    'Zurich - Tech Hub',
    'Zurich - Tech Hub (FTV)',
  ];
  if (profileName) {
    const inHidden =
      hideProfiles.filter((hideProfile) => hideProfile === profileName).length >
      0;
    console.log('inHidden: ', inHidden);
    if (inHidden) {
      return false;
    } else {
      const inShow =
        showProfiles.filter((showProfile) => showProfile === profileName)
          .length > 0;
      if (inShow) {
        console.log('inShow: ', inShow);
        return true;
      } else {
        return false;
      }
    }
  } else {
    return false;
  }
};

export default function App() {
  const [agent, setAgent] = useState();
  const [agentName, setAgentName] = useState('');
  const [agentRoutingProfile, setAgentRoutingProfile] = useState(null);
  const [contact, setContact] = useState(null);
  const [setMediaType] = useState(null);
  const [policyNumber, setPolicyNumber] = useState('');
  const [claimRef, setClaimRef] = useState('');
  const [policyData, setPolicyData] = useState([]);
  const [claimData, setClaimData] = useState([]);
  const [contactAttr, setContactAttr] = useState([]);
  const [newAgentState, setNewAgentState] = useState(null);
  const [oldAgentState, setOldAgentState] = useState(null);

  useEffect(() => {
    if (agent) {
      agent.onStateChange((agentStateChange) => {
        setNewAgentState(agentStateChange.newState);
        setOldAgentState(agentStateChange.oldState);
        setAgentName(agent.getName());
      });
    }
  }, [agent]);

  const contactHandler = (newContact) => {
    setContact(newContact);
    console.log('NEWCONTACT');
    const attrs = newContact.getAttributes();
    console.log('attrs: ', attrs);
    console.log(newContact);

    const attrKeys = Object.keys(attrs);
    console.log('attrKeys: ', attrKeys);

    const attrAry = [];
    for (const index in attrKeys) {
      const key = attrKeys[index];
      console.log('key: ', key);
      console.log('attrs[key]: ', attrs[key]);
      attrAry.push(attrs[key]);

      if (key === 'policynumber') {
        setPolicyNumber(attrs[key].value);
      }
      if (key === 'claimref') {
        setClaimRef(attrs[key].value);
      }
    }
    setContactAttr(attrAry);

    const agentConn = newContact.getAgentConnection();
    const mediaType = agentConn.getMediaType();
    console.log(mediaType);
    setMediaType(mediaType);
    newContact.onEnded(() => {
      handleGoneRoutable();
    });
  };

  const handleGoneRoutable = () => {
    setContactAttr([]);
    setPolicyNumber('');
    setClaimRef('');
    setPolicyData([]);
    setClaimData([]);
  };

  const setAgentHandler = (agent) => {
    setAgent(agent);
    agent.onRoutable(handleGoneRoutable);
    setAgentRoutingProfile(agent.getRoutingProfile());
  };

  const updatePolicyData = (data) => {
    setPolicyData(data);
    setClaimData(data);
  };

  const clearData = (dataToClear) => {
    switch (dataToClear) {
      case 'claim':
        setClaimData([]);
        break;
      case 'policy':
        setPolicyData([]);
        break;
      default:
        break;
    }
  };

  const client = new ApolloClient({
    uri: 'https://hyhr8dhe87.execute-api.eu-west-1.amazonaws.com/prod/graphql',
    cache: new InMemoryCache(),
    request: (operation) => {
      operation.setContext({
        headers: {
          'x-api-key': `xRIb5Mr2A95Pa2xDIrI5hWxRt7Jq9nahZU4pUf9`,
        },
      });
    },
  });

  useEffect(() => {
    async function search() {
      try {
        const data = await Functions.PolicySearch(
          'policynumber',
          policyNumber,
          'policyNumber'
        );
        setPolicyData(data);
      } catch (error) {
        setPolicyData([]);
        console.log(error);
      }
    }
    policyNumber && search();
  }, [policyNumber]);

  useEffect(() => {
    async function search() {
      try {
        const data = await Functions.ClaimSearch(
          'claimreftrimmed',
          claimRef,
          'claimRefTrimmed'
        );
        setClaimData(data);
      } catch (error) {
        setClaimData([]);
        console.log(error);
      }
    }
    claimRef && search();
  }, [claimRef]);

  return (
    <React.Fragment>
      <ApolloProvider client={client}>
        <Router>
          <Layout
            agentProfile={agentRoutingProfile && agentRoutingProfile.name}
          >
            <Switch>
              <Route exact path='/'>
                <Grid container spacing={3}>
                  {agentRoutingProfile &&
                    (!agentRoutingProfile.name.includes('FSG - Complaints') ||
                      !agentRoutingProfile.name.includes(
                        'FSG - Complaints L2'
                      ) ||
                      !agentRoutingProfile.name.includes('A365 - Tier 1')) && (
                      <Grid item xs={12}>
                        <ManualDialFP agent={agent} />
                      </Grid>
                    )}
                  {agentRoutingProfile &&
                    (agentRoutingProfile.name.includes('FSG - Complaints') ||
                      agentRoutingProfile.name.includes(
                        'FSG - Complaints L2'
                      )) && (
                      <Grid item xs={12}>
                        <ManualDial agent={agent} />
                      </Grid>
                    )}
                  {agentRoutingProfile &&
                    agentRoutingProfile.name.includes('A365 - Tier 1') && (
                      <Grid item xs={12}>
                        <ManualDialT1 agent={agent} />
                      </Grid>
                    )}
                  <Grid item>
                    <CCP
                      setAgent={setAgentHandler}
                      setContact={contactHandler}
                    />
                  </Grid>
                  <Grid item>
                    <ContactAttributes attributes={contactAttr} />
                  </Grid>
                  <Grid item xs>
                    {agentRoutingProfile &&
                    showDispositions(agentRoutingProfile.name) ? (
                      <Disposition
                        currentContact={contact}
                        agentName={agentName}
                        newAgentState={newAgentState}
                        oldAgentState={oldAgentState}
                      />
                    ) : null}
                    <PolicySearch
                      updatePolicyData={updatePolicyData}
                      clearData={clearData}
                      hasData={policyData.length > 0}
                    />
                    <PolicyView policyData={policyData} />
                    <ClaimSearch
                      // updateClaimData={updateClaimData}
                      clearData={clearData}
                      hasData={claimData.length > 0}
                    />
                    <ClaimView claimData={claimData} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Stats agentRoutingProfile={agentRoutingProfile} />
                </Grid>
              </Route>
              <Route
                exact
                path='/dispositions'
                component={DispositionOptionsPage}
              />
            </Switch>
          </Layout>
        </Router>
      </ApolloProvider>
    </React.Fragment>
  );
}
