import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialpadIcon from '@material-ui/icons/Dialpad';
import Divider from '@material-ui/core/Divider';
import PresentAs from './present-as';
import ManualDialBtn from './manual-dial-btn';

export default function ManualDial(props) {
  const { agent } = props;
  const [numberToDial, setNumberToDial] = useState('');
  const [presentAs, setPresentAs] = useState('');
  const [enablePresentAs, setEnablePresentAs] = useState(false);

  const handleNumberChange = (e) => {
    setEnablePresentAs(false);
    setPresentAs('');
    setNumberToDial(e.target.value);
  };

  const handleNumberBlur = async () => {
    // 07772297316;
    if (numberToDial) {
      // setLoading(true);
      // try {
      //   const data = await ClaimSearch('tel', numberToDial, 'tel');
      //   const matchedBroker = MatchBroker(data);
      //   if (matchedBroker) {
      //     setPresentAs(matchedBroker);
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
      // setLoading(false);
      setEnablePresentAs(true);
    }
  };

  const handlePresentAsChange = (e) => {
    setPresentAs(e.target.value);
  };

  const handleDial = async () => {
    let tel;
    const telTmp = numberToDial.replace(/\s/g, '');
    if (telTmp.substring(0, 1) === '0') {
      tel = `+44${telTmp.substring(1, telTmp.length)}`;
    }
    // eslint-disable-next-line no-undef
    agent.connect(connect.Endpoint.byPhoneNumber(tel), {
      queueARN: presentAs,
      success: () => {
        // eslint-disable-next-line no-undef
        // connect.contact(async function (contact) {
        //   this.unsubscribe(); // only fire once
        //   const data = {
        //     contactId: contact.contactId,
        //     broker: presentAs,
        //     tel: tel,
        //     agent: agentName,
        //   };
        //   try {
        //     await SubmitContactTier1Broker(data);
        //   } catch (error) {
        //     console.log(error);
        //   }
        // });
      },
      failure: (err) => console.log(err),
    });
  };

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid item md={3} sm={4} xs={12}>
        <TextField
          label="Complaints Team - Manual Dial"
          value={numberToDial}
          onChange={handleNumberChange}
          onBlur={handleNumberBlur}
          fullWidth
          color="secondary"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DialpadIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item md={3} sm={4} xs={12}>
        <PresentAs
          value={presentAs}
          handleChange={handlePresentAsChange}
          disabled={!enablePresentAs}
        />
      </Grid>
      <Grid item>
        <ManualDialBtn
          disabled={!numberToDial || !presentAs}
          dial={handleDial}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>
    </Grid>
  );
}
