import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import Divider from "@material-ui/core/Divider";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import * as Functions from "../functions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: theme.typography.fontSize,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function PolicySearch(props) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = async (searchType) => {
    handleClose();
    try {
      let searchColumn = "policyNumber";

      switch (searchType) {
        case "tel":
          searchColumn = "tel";
          break;
        case "postcode":
          searchColumn = "postcode";
          break;
        case "vrn":
          searchColumn = "vehicleReg";
          break;
        default:
          break;
      }

      const data = await Functions.PolicySearch(
        searchType,
        searchValue,
        searchColumn
      );

      props.updatePolicyData(data);
    } catch (error) {
      console.log(error);
      props.updatePolicyData([]);
    }
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value.toUpperCase());
  };

  const clearData = () => {
    setSearchValue("");
    props.clearData("policy");
  };

  return (
    <Paper className={classes.root}>
      <IconButton className={classes.iconButton} aria-label="search" disabled>
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder="Policy Search"
        inputProps={{ "aria-label": "policy search" }}
        value={searchValue}
        onChange={handleChange}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="clear all"
        disabled={!searchValue && !props.hasData}
        onClick={clearData}
      >
        <ClearAllIcon />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        className={classes.iconButton}
        aria-label="search menu"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem
          key="policynumber"
          disabled={!searchValue}
          onClick={() => {
            handleSearch("policynumber");
          }}
        >
          Policy Number
        </MenuItem>
        <MenuItem
          key="tel"
          disabled={!searchValue}
          onClick={() => {
            handleSearch("tel");
          }}
        >
          Telephone
        </MenuItem>
        <MenuItem
          key="vrn"
          disabled={!searchValue}
          onClick={() => {
            handleSearch("vrn");
          }}
        >
          Vehicle Reg
        </MenuItem>
        <MenuItem
          key="postcode"
          disabled={!searchValue}
          onClick={() => {
            handleSearch("postcode");
          }}
        >
          Postcode
        </MenuItem>
      </Menu>
    </Paper>
  );
}
