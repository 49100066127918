import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  Typography,
} from '@material-ui/core';
import { gql, useMutation } from '@apollo/client';

const ADD_DISPOSITION = gql`
  mutation ADD_DISPOSITION(
    $contactId: String!
    $claimRef: String!
    $user: String!
    $whoCalled: String!
    $reasonForCalling: String!
    $descOfCall: String!
    $capacityProvider: String!
    $callRedirected: Boolean!
  ) {
    addIVRDisposition(
      contactId: $contactId
      claimRef: $claimRef
      user: $user
      whoCalled: $whoCalled
      reasonForCalling: $reasonForCalling
      descOfCall: $descOfCall
      capacityProvider: $capacityProvider
      callRedirected: $callRedirected
    )
  }
`;

export default function DispositionContact({
  contactId,
  inbound,
  currentContact,
  allOpts,
  capacityProviderOpts,
  agentName,
  send,
  updateContact,
}) {
  const [addDisposition, { error, data }] = useMutation(ADD_DISPOSITION);
  const [whoCalledOpts, setWhoCalledOpts] = useState([]);
  const [reasonsForCallingOpts, setReasonsForCallingOpts] = useState([]);
  const [descCallOpts, setDescCallOpts] = useState([]);
  const [claimRef, setClaimRef] = useState('');
  const [whoCalled, setWhoCalled] = useState('');
  const [reasonForCalling, setReasonForCalling] = useState('');
  const [capacityProvider, setCapacityProvider] = useState('');
  const [descOfCall, setDescOfCall] = useState('');
  const [callRedirected, setCallRedirected] = useState(false);
  const [dispositionSent, setDispositionSent] = useState(false);

  useEffect(() => {
    updateContact({
      contactId,
      dispositionSent,
    });
  }, [dispositionSent]);

  useEffect(() => {
    if (send === true && dispositionSent === false) {
      console.log('sending: ', contactId);
      const payload = {
        contactId,
        claimRef,
        user: agentName,
        whoCalled,
        reasonForCalling,
        descOfCall,
        capacityProvider,
        callRedirected,
      };
      console.log('payload: ', payload);
      addDisposition({
        variables: payload,
      });
    }
  }, [send]);

  useEffect(() => {
    if (data && data.addIVRDisposition) {
      console.log('data.addIVRDisposition: ', data.addIVRDisposition);
      setDispositionSent(data.addIVRDisposition);
    }
  }, [data]);

  const selectWhoCalled = (e) => {
    const newWhoCalled = e.target.value;
    setWhoCalled(newWhoCalled);
    const filtered = allOpts
      .filter((opt) => opt.whoCalled === newWhoCalled)
      .map((opt) => opt.reasonForCalling);
    setReasonsForCallingOpts([...new Set(filtered)]);
  };

  const selectReasonForCalling = (e) => {
    const newReasonForCalling = e.target.value;
    setReasonForCalling(newReasonForCalling);
    const filtered = allOpts
      .filter((opt) => {
        return (
          opt.whoCalled === whoCalled &&
          opt.reasonForCalling === newReasonForCalling
        );
      })
      .map((opt) => opt.descOfCall);
    setDescCallOpts([...new Set(filtered)]);
  };

  const selectDescOfCall = (e) => {
    const newDescOfCall = e.target.value;
    setDescOfCall(newDescOfCall);
  };

  const toggleCallRedirected = (event) => {
    setCallRedirected(event.target.checked);
  };

  useEffect(() => {
    if (callRedirected) {
      setWhoCalled('');
      setReasonForCalling('');
      setCapacityProvider('');
      setDescOfCall('');
      setClaimRef('');
    }
  }, [callRedirected]);

  useEffect(() => {
    const filtered = allOpts.map((opt) => opt.whoCalled);
    const set = [...new Set(filtered)];
    setWhoCalledOpts(set);
  }, [allOpts]);

  if (currentContact && dispositionSent === false && inbound) {
    return (
      <>
        <Grid item xs={12}>
          <TextField
            id='filled-hidden-label-small'
            label={
              <Typography style={{ fontSize: '14px' }}>
                Claim Reference
              </Typography>
            }
            variant='outlined'
            size='small'
            disabled={callRedirected}
            fullWidth={true}
            value={claimRef}
            onChange={(e) => setClaimRef(e.target.value)}
            style={{ marginBottom: '1em' }}
          />
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <FormControl
                fullWidth
                style={{ margin: '0em', padding: '0em' }}
                size='small'
              >
                <InputLabel id='who-called-label' style={{ fontSize: '14px' }}>
                  Who Called?
                </InputLabel>
                <Select
                  defaultValue={whoCalledOpts[0]}
                  labelId='who-called-label'
                  id='who-called-select'
                  value={whoCalled}
                  label='Who Called'
                  onChange={selectWhoCalled}
                  disabled={callRedirected}
                >
                  {whoCalledOpts.map((opt, idx) => {
                    return (
                      <MenuItem key={idx} value={opt}>
                        <Typography style={{ fontSize: '14px' }}>
                          {opt}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth size='small'>
                <InputLabel
                  id='reason-for-calling-label'
                  style={{ fontSize: '14px' }}
                >
                  Reason for Call?
                </InputLabel>
                <Select
                  defaultValue=''
                  labelId='reason-for-calling-label'
                  id='reason-for-calling-select'
                  value={reasonForCalling}
                  label='Who Called'
                  disabled={whoCalled ? false : true}
                  onChange={selectReasonForCalling}
                >
                  {reasonsForCallingOpts.map((opt, idx) => {
                    return (
                      <MenuItem key={idx} value={opt}>
                        <Typography style={{ fontSize: '14px' }}>
                          {opt}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth size='small'>
                <InputLabel
                  id='desc-of-call-label'
                  style={{ fontSize: '14px' }}
                >
                  Description of Call?
                </InputLabel>
                <Select
                  defaultValue=''
                  labelId='desc-of-call-label'
                  id='desc-of-call'
                  value={descOfCall}
                  disabled={reasonForCalling ? false : true}
                  label='Who Called'
                  onChange={selectDescOfCall}
                >
                  {descCallOpts.map((opt, idx) => {
                    return (
                      <MenuItem key={idx} value={opt}>
                        <Typography style={{ fontSize: '14px' }}>
                          {opt}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth size='small'>
                <InputLabel
                  id='capacity-provider-select-label'
                  style={{ fontSize: '14px' }}
                >
                  Capacity Provider?
                </InputLabel>
                <Select
                  defaultValue={capacityProviderOpts[0]}
                  labelId='capacity-provider-select-label'
                  id='capacity-provider-select'
                  value={capacityProvider}
                  disabled={callRedirected}
                  label='Who Called'
                  onChange={(e) => setCapacityProvider(e.target.value)}
                >
                  {capacityProviderOpts.map((opt, idx) => {
                    return (
                      <MenuItem key={idx} value={opt}>
                        <Typography style={{ fontSize: '14px' }}>
                          {opt}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <FormControlLabel
            style={{ marginTop: '1em' }}
            control={
              <Checkbox
                checked={callRedirected}
                onChange={toggleCallRedirected}
              />
            }
            label={
              <Typography style={{ fontSize: '14px' }}>
                Call Redirected
              </Typography>
            }
          />
        </Grid>
      </>
    );
  } else {
    return null;
  }
}
