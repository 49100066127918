import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
}))(TableCell);

export default function ContactAttributes(props) {
  const { attributes } = props;
  return (
    <React.Fragment>
      {attributes.length > 0 ? (
        <TableContainer component={Paper}>
          <Table size="small" aria-label="contact attributes table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="right">
                  <b>Name</b>
                </StyledTableCell>
                <StyledTableCell>
                  <b>Value</b>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attributes.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="right" component="th" scope="row">
                    <b>{item.name}:</b>
                  </TableCell>
                  <TableCell>{item.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </React.Fragment>
  );
}
