import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import AdditionalDrivers from './additional-drivers';

export default function PolicyTransaction(props) {
  const { data } = props;
  return (
    <ExpansionPanel TransitionProps={{ unmountOnExit: true }}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="body2">{`${data.transactionCode} - ${
          data.transactionDescription
        } - Effective From: ${moment(data.effectiveDate).format(
          'lll'
        )}`}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              display="inline"
            >
              Level of Cover:{' '}
            </Typography>
            <Typography variant="body2" display="inline">
              {data.levelOfCover}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Insured Name:
            </Typography>
            <Typography variant="body2">{data.insuredFullname}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Insured DOB:
            </Typography>
            <Typography variant="body2">
              {moment(data.insuredDOB).format('ll')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Insured Address:
            </Typography>
            <Typography variant="body2">{data.addressLine1}</Typography>
            <Typography variant="body2">{data.addressLine2}</Typography>
            <Typography variant="body2">{data.addressLine3}</Typography>
            <Typography variant="body2">{data.clientPostcode}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="textSecondary">
              Vehicle:{' '}
            </Typography>
            <Typography variant="body2">{`${data.vehicleMake} ${data.vehicleModel} ${data.vehicleReg}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              Additional Drivers:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <AdditionalDrivers drivers={data.additionalDrivers || []} />
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
