import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.secondary.main,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  visible: {
    position: 'relative',
    minHeight: 160,
    marginTop: 30,
    borderRadius: 4,
  },
  openContainer: {
    display: 'flex',
    height: 90,
  },
  closedContainer: {
    display: 'none',
  },
}));

export default function LoadingBackdrop(props) {
  const classes = useStyles();
  const { open } = props;

  return (
    <Backdrop
      className={clsx(classes.backdrop, open ? classes.visible : '')}
      open={open}
    >
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        justifyContent="space-evenly"
        className={clsx(open ? classes.openContainer : classes.closedContainer)}
      >
        <Typography variant="overline" color="textSecondary">
          Loading Agent Dashboard
        </Typography>
        <CircularProgress color="inherit" />
      </Grid>
    </Backdrop>
  );
}
