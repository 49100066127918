import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { GetBrokerListT1 } from '../functions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
    width: '100%',
  },
  textFieldProgress: {
    // color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  notVisible: {
    display: 'none',
  },
}));

export default function PresentAsT1(props) {
  const classes = useStyles();
  const { value, handleChange, disabled } = props;
  const brokers = GetBrokerListT1();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <TextField
          label="Present As"
          fullWidth
          disabled={disabled}
          value={value}
          onChange={handleChange}
          color="secondary"
          variant="outlined"
          select
        >
          <MenuItem
            key={9999}
            value=""
            className={classes.notVisible}
          ></MenuItem>
          {brokers.map((b, idx) => (
            <MenuItem key={idx} value={b.value}>
              {b.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </div>
  );
}
